<template>
	<div class="flex flex-col mt-5">
		<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
			<div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
				<table class="min-w-full">
					<thead>
						<tr>
							<th class="thead">Name</th>
							<!-- <th class="thead">Modified</th> -->
							<th class="thead">Used</th>
							<th class="thead">Data Type</th>
							<th class="thead">GDPR</th>
							<th class="thead"></th>
						</tr>
					</thead>

					<tbody class="bg-white">
						<tr v-for="(field, i) in fields" :key="field.id + i">
							<td class="px-6 py-4  border-b border-gray-200">
								<div class="text-md leading-5 text-gray-900">{{ field.name }}</div>
								<div class="text-xs leading-5 text-gray-500">{{ field.description }}</div>
							</td>
							<!-- <td v-if="!field.updated" class="td">{{ field.created | ago }}</td>
							<td v-else class="td">{{ field.updated | ago }}</td> -->
							<td class="td">
								<router-link
									v-for="assignedEntity in field.assignedTo"
									:key="assignedEntity.id"
									:to="{
										name: 'model',
										params: { id: assignedEntity.id, name: shortName(assignedEntity.name) }
									}"
									class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-pink-100 text-pink-800 mr-1"
								>
									{{ assignedEntity.name }}
								</router-link>
							</td>
							<td class="td">{{ field.dataType }}</td>
							<td class="td">
								<span :class="getGdpr(field.gdprStatus).style" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
									{{ getGdpr(field.gdprStatus).text }}
								</span>
							</td>
							<td class="td">
								<router-link :to="{ name: 'edit-field', params: { id: field.id, field } }">
									<!-- <font-awesome-icon :icon="['fad', 'edit']" /> -->
									Edit
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FieldList',
	props: {
		fields: {
			type: Array,
			default: function() {
				return []
			}
		}
	},
	methods: {
		getGdpr(status) {
			switch (status) {
				case 'sensitive':
					return { style: 'bg-red-100 text-red-800', text: 'Sensitive' }
				case 'personal':
					return { style: 'bg-orange-100 text-orange-800', text: 'Personal' }
				default:
					return { style: 'bg-green-100 text-green-800', text: 'N/A' }
			}
		}
	}
}
</script>
