<template>
	<div class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
		<div class="px-4 py-4 flex items-center sm:px-6">
			<div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
				<div>
					<div class="text-sm leading-5 font-medium text-indigo-600 truncate">
						{{ field.name }}
					</div>
					<div class="mt-2 flex">
						<div class="flex items-center text-sm leading-5 text-gray-500">
							<span>
								{{ field.description }}
							</span>
						</div>
					</div>
				</div>
				<div class="mt-4 flex-shrink-0 sm:mt-0">
					<span
						:class="{ 'bg-gray-200': !isAssigned, 'bg-indigo-600': isAssigned }"
						class="relative inline-block flex-no-shrink h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
						role="checkbox"
						tabindex="0"
						:aria-checked="isAssigned.toString()"
						@click="isAssigned = !isAssigned"
						@keydown.space.prevent="isAssigned = !isAssigned"
					>
						<span
							aria-hidden="true"
							:class="{ 'translate-x-5': isAssigned, 'translate-x-0': !isAssigned }"
							class="relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
						>
							<span
								:class="{ 'opacity-0 ease-out duration-100': isAssigned, 'opacity-100 ease-in duration-200': !isAssigned }"
								class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
							>
								<svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
									<path
										d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
										stroke="currentColor"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</span>
							<span
								:class="{ 'opacity-100 ease-in duration-200': isAssigned, 'opacity-0 ease-out duration-100': !isAssigned }"
								class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
							>
								<svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
									<path
										d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
									/>
								</svg>
							</span>
						</span>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { configureMixin } from '@/mixins/configMixins'
export default {
	name: 'ConfigureField',
	mixins: [configureMixin(true, false)],
	props: {
		field: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	data: function() {
		return {
			operates: 'rules',
			plural: 'fields'
		}
	},
	mounted() {
		this.configId = this.field.id
		this.configName = this.shortName(this.field.name)
	}
}
</script>
