<template>
	<form @submit.prevent>
		<confirmation
			:title="confirm.title"
			:message="confirm.message"
			:ok-button="confirm.okButton"
			:open="confirm.open"
			@ok="deleteConfirmed"
			@cancel="deleteCancelled"
		/>
		<div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
			<div class="md:grid md:grid-cols-3 md:gap-6">
				<div class="md:col-span-1">
					<h3 class="text-lg font-medium leading-6 text-gray-900">General</h3>
					<div class="mt-1 text-sm leading-5 text-gray-500">
						<p class="mt-3">
							An Field is an asset which is created by a Creator and is used by Entities.
						</p>
					</div>
					<button v-if="loaded" class="text-xs text-red-200" @click="confirm.open = true">Delete</button>
				</div>
				<div class="mt-5 md:mt-0 md:col-span-2">
					<div class="grid grid-cols-3 gap-6">
						<div class="col-span-4">
							<label for="fieldName" class="block text-sm font-medium leading-5 text-gray-700">
								Field Name
							</label>
							<div class="mt-1 rounded-md shadow-sm">
								<input
									id="fieldName"
									v-model.lazy="item.name"
									type="fieldName"
									class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
								/>
							</div>
							<p v-if="!$v.item.name.required" class="text-red-500 text-xs italic">Name field is required</p>
						</div>
						<div class="col-span-4">
							<label for="description" class="block text-sm font-medium leading-5 text-gray-700">
								Description
							</label>
							<div class="mt-1 rounded-md shadow-sm">
								<textarea
									id="description"
									v-model.lazy="item.description"
									rows="3"
									class="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
								></textarea>
							</div>
							<p class="mt-2 text-sm text-gray-500">Field Description.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
			<div class="md:grid md:grid-cols-3 md:gap-6">
				<div class="md:col-span-1">
					<h3 class="text-lg font-medium leading-6 text-gray-900">Data Information</h3>
					<p class="mt-1 text-sm leading-5 text-gray-500">
						A bit of information about what data is being used here.
					</p>
				</div>
				<div class="mt-5 md:mt-0 md:col-span-2">
					<div class="grid grid-cols-3 gap-6">
						<div class="col-span-4 md:col-span-1">
							<label for="dataType" class="block text-sm font-medium leading-5 text-gray-700">
								Data Type
							</label>
							<div class="mt-1 rounded-md shadow-sm">
								<select
									id="dataType"
									v-model="item.dataType"
									class="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
								>
									<option v-for="o in basicOptions" :key="o.value" :value="o.value">{{ o.text }}</option>
								</select>
							</div>
						</div>
						<div class="col-span-4">
							<div class="relative flex items-start">
								<div class="absolute flex items-center h-5">
									<input
										id="required"
										v-model="item.isRequired"
										type="checkbox"
										class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
									/>
								</div>
								<div class="pl-7 text-sm leading-5">
									<label for="required" class="font-medium text-gray-700">Required</label>
									<p v-if="item.isRequired" class="text-gray-500">The field is required.</p>
									<p v-else class="text-gray-500">The field is not required.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
			<div class="md:grid md:grid-cols-3 md:gap-6">
				<div class="md:col-span-1">
					<h3 class="text-lg font-medium leading-6 text-gray-900">GDPR</h3>
					<p class="mt-1 text-sm leading-5 text-gray-500">
						Provide information which will help ensure GDPR compliance.
					</p>
				</div>
				<div class="mt-5 md:mt-0 md:col-span-2">
					<div class="grid grid-cols-3 gap-6">
						<div class="col-span-4">
							<fieldset>
								<legend class="text-base font-medium text-gray-900">
									GDPR
								</legend>
								<p class="text-sm leading-5 text-gray-500">GDPR Compliance.</p>
								<div class="mt-4">
									<div class="flex items-center">
										<input
											id="gdpr-no"
											v-model="item.gdprStatus"
											value="no"
											name="gdprStatus"
											type="radio"
											class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
										/>
										<label for="gdpr-no" class="ml-3">
											<span class="block text-sm leading-5 font-medium text-gray-700">Not Applicable</span>
										</label>
									</div>
									<div class="flex items-center">
										<input
											id="gdpr-personal"
											v-model="item.gdprStatus"
											value="personal"
											name="gdprStatus"
											type="radio"
											class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
										/>
										<label for="gdpr-personal" class="ml-3">
											<span class="block text-sm leading-5 font-medium text-gray-700">Personal</span>
										</label>
									</div>
									<div class="flex items-center">
										<input
											id="gdpr-sensitive"
											v-model="item.gdprStatus"
											value="sensitive"
											name="gdprStatus"
											type="radio"
											class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
										/>
										<label for="gdpr-sensitive" class="ml-3">
											<span class="block text-sm leading-5 font-medium text-gray-700">Sensitive</span>
										</label>
									</div>
								</div>
							</fieldset>
							<p v-if="!$v.item.gdprStatus.required" class="text-red-500 text-xs italic mt-3">GDPR Status is required</p>
						</div>
						<div v-if="isGdpr" class="col-span-4">
							<label for="gdprPurpose" class="block text-sm font-medium leading-5 text-gray-700">
								GDPR Purpose
							</label>
							<div class="mt-1 rounded-md shadow-sm">
								<textarea
									id="gdprPurpose"
									v-model.lazy="item.gdprPurpose"
									rows="3"
									class="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
								></textarea>
							</div>
							<p class="mt-2 text-sm text-gray-500">Provide a brief reason why/how the data is being used</p>
						</div>
						<div v-if="isGdpr" class="col-span-1">
							<label for="retentionPeriod" class="block text-sm leading-5 font-medium text-gray-700">Retention Period</label>
							<div class="mt-1 relative rounded-md shadow-sm">
								<input
									id="retentionPeriod"
									v-model.lazy="item.retentionPeriod"
									class="form-input block w-full pr-12 sm:text-sm sm:leading-5"
									placeholder="1"
								/>
								<div class="absolute inset-y-0 right-0 flex items-center">
									<select
										v-model.lazy="item.retentionPeriodScope"
										aria-label="retentionPeriodScope"
										class="form-select h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm sm:leading-5"
									>
										<option v-for="p in retentionScope" :key="p.value" :value="p.value">{{ p.text }}</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-8 border-t border-gray-200 pt-5">
			<div class="flex justify-end">
				<span class="inline-flex rounded-md shadow-sm">
					<router-link
						:to="{ name: 'fields' }"
						tag="button"
						type="cancel"
						class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
					>
						Cancel
					</router-link>
				</span>
				<span class="ml-3 inline-flex rounded-md shadow-sm">
					<button
						type="submit"
						class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
						@click="save"
					>
						Save
					</button>
				</span>
			</div>
		</div>
	</form>
</template>
<script>
// import { FieldService as Service } from '@/store/common/api.service'
import { mapGetters } from 'vuex'
import { createMixin } from '@/mixins/configMixins'
import { required } from 'vuelidate/lib/validators'
export default {
	name: 'FieldCreator',
	mixins: [createMixin('fields')],
	data() {
		return {
			basicOptions: [
				{ text: 'Text', value: 'string' },
				{ text: 'Number', value: 'number' },
				{ text: 'Date/Time', value: 'datetime' },
				{ text: 'Yes/No', value: 'boolean' },
				{ text: 'List', value: 'array' },
				{ text: 'Currency', value: 'currency' }
			],
			gdprOptions: [
				{ text: 'N/A', value: 'no' },
				{ text: 'Personal Data', value: 'personal' },
				{ text: 'Sensitive Personal Data', value: 'sensitive' }
			],
			retentionScope: [
				{ text: 'Days', value: 'days' },
				{ text: 'Weeks', value: 'weeks' },
				{ text: 'Months', value: 'months' },
				{ text: 'Years', value: 'years' }
			]
			// gdprStatus: 'no',
			// gdprPurpose: '',
			// dataType: ''
		}
	},
	validations: {
		item: {
			name: {
				required
			},
			description: {},
			gdprStatus: {
				required
			},
			gdprPurpose: {},
			dataType: { required }
		}
	},
	computed: {
		...mapGetters('connections', ['target', 'source']),
		isGdpr: function() {
			return this.item.gdprStatus !== 'no'
		}
	},
	watch: {
		// eslint-disable-next-line no-unused-vars
		item: function(val, oldVal) {
			if (val) {
				this.loadDataType(val.dataType)
				this.loadGDPRType(val.gdprStatus)
			}
		}
	},
	methods: {
		loadDataType(key) {
			this.basicOptions.forEach((option) => {
				option.selected = false
			})
			let selectedOption = this.basicOptions.find((o) => o.value === key)
			if (selectedOption) {
				this.$set(selectedOption, 'selected', true)
				this.basicOptions.sort()
			}
		},
		loadGDPRType(key) {
			this.gdprOptions.forEach((option) => {
				option.selected = false
			})
			let selectedOption = this.gdprOptions.find((o) => o.value === key)
			if (selectedOption) {
				this.$set(selectedOption, 'selected', true)
				this.gdprOptions.sort()
			}
		},
		getSelectValue(i) {
			this.item.dataType = i
		},
		getGDPRValue(i) {
			this.item.gdprStatus = i
		},
		modelSource(modelId) {
			return this.source(this.item.id).filter((c) => c.modelId == modelId)
		},
		modelTarget(modelId) {
			return this.target(this.item.id).filter((c) => c.modelId == modelId)
		}
	}
}
</script>
