<template>
	<div>
		<top-bar title="Configuration" :action="topAction" />
		<div>
			<div class="md:grid md:grid-cols-3 md:gap-6">
				<div class="md:col-span-1 mt-5">
					<div class="px-4 sm:px-0">
						<h3 class="text-lg font-medium leading-6 text-gray-900">Fields</h3>
						<p class="mt-1 text-sm leading-5 text-gray-500">
							Edit Fields for Model
						</p>
					</div>
				</div>
				<div class="mt-5 md:col-span-2">
					<form @submit.prevent>
						<div class="shadow sm:rounded-md sm:overflow-hidden">
							<div class="px-4 py-5 bg-white sm:p-6">
								<div class="grid grid-cols-3 gap-6">
									<div class="col-span-3">
										<label for="username" class="block text-sm font-medium leading-5 text-gray-700">
											Enable Fields for
											<strong>{{ model.name }}</strong>
										</label>

										<div class="mt-5 rounded-md">
											<ul>
												<li v-for="f in fields" :key="f.id + 'field'">
													<config-field ref="childField" :field="f" />
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { configureMixin } from '@/mixins/configMixins'
import topBar from '@/components/shared/topBar'
import configField from './configField'
import { mapState } from 'vuex'
export default {
	name: 'ConfigureField',
	components: { topBar, configField },
	mixins: [configureMixin()],
	data: function() {
		return {
			operates: 'rules',
			plural: 'fields'
		}
	},
	computed: {
		...mapState({
			fields: (state) => state.fields.all
		}),
		topAction() {
			return {
				name: 'Back to Configuration',
				to: { name: 'model-config', params: { id: `${this.model.id}`, name: `${this.model._name}` } }
			}
		}
	}
}
</script>
