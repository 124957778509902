<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'Fields',
	data() {
		return {
			actions: [{ name: 'Field', route: 'create-field', icon: 'plus-circle' }]
		}
	},
	metaInfo() {
		return {
			title: 'Fields'
		}
	}
}
</script>
