#‹
<template>
	<div class="bg-gray-100">
		<div class="max-w-7xl mx-auto py-12 sm:px-6 lg:px-8">
			<div class="max-w-3xl mx-auto">
				<div class="bg-white shadow sm:rounded-lg">
					<div class="px-4 py-5 sm:p-6">
						<h3 class="text-lg leading-6 font-medium text-gray-900">
							There are no Fields at the moment, but not for long...
						</h3>
						<div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
							<p>
								A Field is a single piece of data, and is grouped to represent an Entity
							</p>
						</div>
						<div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
							<p>
								For example a
								<em>Field</em>
								called Display Title could be used by the RSS, Whiteboard and Social Media (
								<em>Channels</em>
								)
							</p>
						</div>
						<model-animation />
						<div class="mt-3 text-sm leading-5">
							<router-link
								:to="{ name: 'create-field' }"
								class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
							>
								Create your first Field →
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import modelAnimation from '@/components/shared/modelAnimation'
export default {
	name: 'EmptyFields',
	components: {
		modelAnimation
	}
}
</script>
